import { Box, Container, Grid, Typography } from "@mui/material";
import type { NextPage } from "next";
import Head from "next/head";
import PageNotFoundImg from "../../public/img/page-not-found-illustration.svg";

import { useTranslation } from "react-i18next";
import { DashboardLayout } from "../components/layout/dashboard-layout/dashboard-layout";
import AppButton from "../components/share/app-button";
import { useRouter } from "next/router";
import { useScaledValue } from "../hooks/use-scaled-value";
import { EAPP_ROUTE } from "../enums/route.enum";

const PageNotFound: NextPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const scaledValue = useScaledValue();

  const RedirectToDashboard = () => {
    router.push(EAPP_ROUTE.MAIN);
  };
  const styles: Types.ObjectMap = {
    image: {
      width: "100%",
    },
    center: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      minWidth: scaledValue(310),
      backgroundColor: "#fff",
      paddingTop: scaledValue(30),
      paddingBottom: scaledValue(30),
      paddingLeft: "10%",
      paddingRight: "10%",
    },
    gridCenter: {
      justifyContent: "center",
      alignItems: "center",
      direction: "column",
      textAlign: "center",
    },
    message: {
      padding: scaledValue(15),
      fontWeight: "bold",
      fontSize: scaledValue(20),
    },
    button: {
      fontWeight: "bold",
      fontSize: scaledValue(13),
      paddingLeft: scaledValue(40),
      paddingRight: scaledValue(40),
    },
  };

  return (
    <>
      <Head>
        <title>{t("error_page_not_found")} | Parsly</title>
      </Head>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 3,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container maxWidth="xl">
          <Grid container style={styles.gridCenter}>
            <Grid item xs={12}>
              <Typography variant="h1">
                {t("error_pages__404__title")}
              </Typography>
              <Typography variant="subtitle1">
                {t("error_pages__404__description")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <PageNotFoundImg style={styles.image} />
            </Grid>

            <Grid item>
              <AppButton
                color="primary"
                variant="outlined"
                size="small"
                onClick={RedirectToDashboard}
                style={styles.button}
              >
                {t("error_pages__404__button_action")}
              </AppButton>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

PageNotFound.getLayout = (page) => <DashboardLayout>{page}</DashboardLayout>;
export default PageNotFound;
